.video-js .vjs-overlay{
    color:#fff;
    position:absolute;

}
/*.video-js .vjs-overlay-no-background{
    max-width:50%
}*/
.video-js .vjs-overlay-background{
    width: 100% ;
    height: 100%;
    margin: auto;
    background: none;
    left:0;
    top:0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*.video-js .vjs-overlay-top-left{
    top:5px;
    left:5px
}
.video-js .vjs-overlay-top{
    left:50%;
    margin-left:-16.5%;
    top:5px
}
.video-js .vjs-overlay-top-right{
    right:5px;
    top:5px;
    width: 100px;
    height: 100px;
}
.video-js .vjs-overlay-right{
    right:5px;
    !*top:50%;*!
    width: 100px;
    height: 100px;
    transform:translateY(-50%)
}
.video-js .vjs-overlay-bottom-right{
    bottom:3.5em;
    right:5px
}
.video-js .vjs-overlay-bottom{
    bottom:3.5em;
    left:50%;
    margin-left:-16.5%
}
.video-js .vjs-overlay-bottom-left{
    bottom:3.5em;
    left:5px
}
.video-js .vjs-overlay-left{
    left:5px;
    top:50%;
    transform:translateY(-50%)
}
.video-js .vjs-overlay-center{
    left:50%;
    margin-left:-16.5%;
    top:50%;
    transform:translateY(-50%)
}
.video-js .vjs-no-flex .vjs-overlay-left,.video-js .vjs-no-flex .vjs-overlay-center,.video-js .vjs-no-flex .vjs-overlay-right{
    margin-top:-15px
}
*/

.display-clock {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 40px;
    line-height: 40px;
    background-repeat:no-repeat;
    border: none;
    overflow: hidden;
    outline:none;
    text-align: center;
}
