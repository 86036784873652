.timeline {
    margin-top: 70px;
    height: 4px;
    background-color: pink;
    font-size: 9pt;
    font-family: 나눔고딕,NanumGothic,돋움,Dotum;
}

.timeline-progress {
    width: 0;
    height: 100%;
    background-color: deeppink;
}

.timeline-items {
    margin-left: -18px;
    margin-right: -10px;
    margin-top: -7px;
    display: flex;
   /* justify-content: space-between;*/
}

.timeline-item {
    position: relative;
}

.timeline-item::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: pink;
    display: block;
    border-radius: 100%;
}

.timeline-content-bottom {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: pink;
    /*width: 100px;*/
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.timeline-content-bottom::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: pink;
    display: block;
    position: absolute;
    top: -8px;
    margin-left: -2px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
}

.timeline-content-top {
    position: absolute;
    top: -57px;
    left: 50%;
    transform: translateX(-50%);
    background-color: pink;
    /*width: 100px;*/
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
}

.timeline-content-top::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: pink;
    display: block;
    position: absolute;
    top: 38px;
    margin-left: -2px;
    left: 50%;
    transform: rotate(-45deg) translateX(-50%);
}

.timeline-item.active::before {
    background-color: deeppink;
}

.timeline-item.active .timeline-content-bottom {
    color: #fff;
    background-color: deeppink;
}

.timeline-item.active .timeline-content-top {
    color: #fff;
    background-color: deeppink;
}

.timeline-item.active .timeline-content-bottom::before {
    background-color: deeppink;
}

.timeline-item.active .timeline-content-top::before {
    background-color: deeppink;
}