.top-drawer-container {
    top: -200px;
    left: 0px;
    height: 200px;
    width: 100vh;
    background-color: rgba(255,255,255,0.5);
    background-repeat:no-repeat;
    border: solid 1px white;
    transform: translateY(0px);
    position:absolute;
    display: none;
}

.top-drawer-container-is-open {
    transform: translateY(200px);
}

.top-drawer-button-container {
    width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255,255,255,0.5);
    background-repeat:no-repeat;
    border: solid 1px white;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    text-align: center;
    display: none;
}

.right-drawer-container {
    top: 0;
    right: 0px;
    height: 100vh;
    width: 200px;
    background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1)) /*Standard*/ /*rgba(255,255,255,0.5)*/;
    transform: translate(200px);
    position:absolute;

}

.right-drawer-container-is-open {
    transform: translate(0px);
}

.right-drawer-button-container {
    width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255,255,255,0.5);
    background-repeat:no-repeat;
    border: solid 1px white;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    text-align: center;
    display: none;
}

.bottom-drawer-container {
    bottom: 0;
    left: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1)) /*Standard*/ /*rgba(255,255,255,0.5)*/;
    transform: translateY(200px);
    position:absolute;
}

.bottom-drawer-container-is-open {
    transform: translateY(0px);
}

.bottom-drawer-button-container {
    width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255,255,255,0.5);
    background-repeat:no-repeat;
    border: solid 1px white;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    text-align: center;
    display: none;
}

#vjs-wrapper:hover .top-drawer-container {
    display: block;
}
.drawer-close::before {
    content: 'x'; /* here is your X(cross) sign. */
    color: #fff;
    font-size: 14pt;
    font-weight: 300;
    font-family: Arial, sans-serif;
    margin: 10px;
}

/*
#vjs-wrapper:hover .bottom-drawer-container  {
    display: block;
}

#vjs-wrapper:hover .right-drawer-container {
    display: block;
}

#vjs-wrapper:hover .top-drawer-button-container  {
    display: block;
}

#vjs-wrapper:hover .bottom-drawer-button-container  {
    display: block;
}

#vjs-wrapper:hover .right-drawer-button-container {
    display: block;
}*/
